import React, { useEffect, useState } from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => {
  if (typeof window !== `undefined`) { 
    //window.location = '/'
  }
  if (typeof window !== `undefined`) { 
    //window.location = '/'
  }
  const [ready, setReadyState] = useState(false);
  
  useEffect(() => {
    setReadyState(true)
  }, [])

  return (
    <div>
      <Seo title={ready ? "404: Not Found" : 'Loading...'}/>

      <Layout>
        <div style={{
          padding: '307px 0 200px',
          textAlign: 'center'
        }}
        >
         {ready && <h1>404: Not Found</h1> }
         {ready && <p style={{fontSize: '1.7rem'}}>Sorry, we can’t find the page you were looking for.</p> }
         { !ready && <p style={{fontSize: '1.7rem'}}>Loading content...</p>}
        </div>
      </Layout>
    </div>
    
  )
}

export default NotFoundPage

